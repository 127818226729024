import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    "Content-Type": "application/json",
    "x-api-key": process.env.REACT_APP_AWS_GATEWAY_API_KEY,
  },
});

export const getData = async (endpoint) => {
  const { data } = await apiClient.get(`/${endpoint}`);
  return data;
};

export const postData = async (endpoint, payload) => {
  const { data } = await apiClient.post(`/${endpoint}`, payload);
  return data;
};
