export const themes = {
  light: {
    background: "#fafafa", // Slightly off-white for a softer look
    color: "#333", // Dark gray for text, easier on the eyes than pure black

    header: {
      TitleColor: "#444", // Using Bootstrap's primary blue for a fresh look
      background: "#f1f1f1", // Slightly gray background for contrast
      color: "#444", // Slightly darker text
      LinkBackgroundColor: "#e9e9e9", // Matching the TitleColor
    },

    dynamicfont: {
      color: "#ff4500",
    },

    footer: {
      background: "#e9e9e9", // Light gray for distinction
      color: "#444", // Slightly darker text
    },

    li: {
      activebackground: "#1dc507", // A darker shade of blue matching LinkActiveColor
      color: "#444",
      background: "#e9e9e9", // Matching the header TitleColor for consistency
      background1: "#E8B8B8", // Matching the header TitleColor for consistency
      background2: "#BFB8E8", // Matching the header TitleColor for consistency
      background3: "#BBE9B8", // Matching the header TitleColor for consistency
    },

    chatboxBackground: "#f1f1f1", // Consistent with header background
    chatboxTextColor: "#333", // Dark gray text for better readability
    chatboxUserBGColor: "#fff", // Pure white to make user messages stand out
    chatboxGPTBGColor: "#E8B8B8", // Lighter blue to make GPT messages distinguishable
  },
  dark: {
    background: "#181818", // Slightly softer black for main background
    color: "#eaeaea", // Slightly off-white text for better readability

    header: {
      TitleColor: "#faebd7", // Using a more vibrant yellow
      background: "#333", // Slightly lighter grey to create contrast
      color: "#faebd7", // Slightly off-white
      LinkBackgroundColor: "#181818", // Dark gray but distinguishable from header background
    },

    dynamicfont: {
      color: "#ffff00",
    },

    footer: {
      background: "#2c2c2c", // Just a bit lighter than the main background for contrast
      color: "#eaeaea", // Slightly off-white text
    },

    li: {
      activebackground: "#158b05", // More neutral dark gray
      color: "#eaeaea",
      background: "#005F5F", // The same vibrant yellow as the header TitleColor
      background1: "#5F0000", // Matching the header TitleColor for consistency
      background2: "#00365F", // Matching the header TitleColor for consistency
      background3: "#305F00", // Matching the header TitleColor for consistency
    },

    chatboxBackground: "#333", // Consistency with header background
    chatboxTextColor: "#eaeaea", // Slightly off-white for better readability
    chatboxUserBGColor: "#1f1f1f", // Dark gray, lighter than main background
    chatboxGPTBGColor: "#005F5F", // Even lighter grey for GPT messages to make them stand out
  },
};
