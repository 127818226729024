import React from "react";

export const Chatbox = ({
  isThinking,
  submitHandler,
  message,
  setMessage,
  textEntered,
  setTextEntered,
}) => {
  return (
    <div className="messages">
      <div className="thinking-box">
        <div className="thinking">
          {isThinking ? "👧 Zoe is thinking..." : null}
        </div>
      </div>
      <form
        className="messageform"
        onSubmit={(e) => {
          e.preventDefault();
          if (textEntered) {
            submitHandler(e);
          }
        }}
      >
        <textarea
          style={{ resize: "none", overflowY: "hidden" }}
          rows={1}
          placeholder="Ask your question..."
          className="input"
          spellCheck="false"
          value={message}
          onChange={(e) => {
            setMessage(e.target.value);
            e.target.value.trim() !== ""
              ? setTextEntered(true)
              : setTextEntered(false);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              submitHandler(e);
            }
          }}
        />
        <button
          className="send-btn"
          disabled={!textEntered}
          style={!textEntered ? { backgroundColor: "gray" } : null}
        >
          <i className="fa fa-arrow-right" style={{ color: "white" }}></i>
        </button>
      </form>
    </div>
  );
};
