import React, { useContext } from "react";
import { ThemeContext } from "../../utils/ThemeContext";

export const Conversationbox = ({ messages }) => {
  const { theme } = useContext(ThemeContext);

  return (
    <div
      className="chatbox"
      style={{ backgroundColor: theme.chatboxBackground }}
    >
      <div className="chatbox-text" style={{ color: theme.chatboxTextColor }}>
        {messages.map((msg, index) => (
          // <div key={index} className={`message-container ${msg.sender}`}>
          <div
            key={index}
            className={`message-container`}
            style={{
              backgroundColor:
                msg.sender === "user"
                  ? theme.chatboxUserBGColor
                  : theme.chatboxGPTBGColor,
              color: theme.chatboxTextColor,
            }}
          >
            {msg.message}
          </div>
        ))}
      </div>
    </div>
  );
};
