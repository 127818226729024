import React, { useContext } from "react";

import { ThemeContext } from "../../utils/ThemeContext";
import { themes } from "../../utils/Themes";
import "./footer.css";

const Footer = () => {
  const { setTheme } = useContext(ThemeContext);
  const { theme } = useContext(ThemeContext);

  return (
    <div>
      <footer
        style={{
          backgroundColor: theme.footer.background,
          color: theme.footer.color,
        }}
      >
        <p>&copy; 2023 Syed Nadeem Ahmed</p>
        <div className="circles">
          <span
            className="circle light"
            onClick={() => setTheme(themes.light)}
          ></span>

          <span
            className="circle dark"
            onClick={() => setTheme(themes.dark)}
          ></span>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
