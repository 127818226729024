import React, { createContext, useState, useEffect } from "react";

import { getData } from "../APIs/BackendAPIs";

export const ResumeContext = createContext();

export const ResumeProvider = ({ children }) => {
  const [resume, setResume] = useState(null);

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const Resume = await getData("profile/resume");
        setResume(Resume.resume);
      } catch (error) {
        console.error("Error fetching resume:", error);
      }
    };
    fetchAllData();
  }, []);

  return (
    <ResumeContext.Provider value={{ resume }}>
      {children}
    </ResumeContext.Provider>
  );
};
