export const simulateTyping = (fullMessage, sender, setMessages) => {
  let i = 0;
  let message = "";
  const words = fullMessage.split(" ");

  // Add an empty message to hold the typing simulation
  setMessages((prevMessages) => [...prevMessages, { message: "", sender }]);

  const interval = setInterval(() => {
    message += words[i] + " ";
    i++;

    setMessages((prevMessages) => {
      // Create a new array and replace the last message with the updated one
      const newMessages = [...prevMessages];
      newMessages[newMessages.length - 1] = { message, sender };
      return newMessages;
    });

    if (i >= words.length) {
      clearInterval(interval);
    }
  }, 100); // 100 milliseconds between each word
};
