import { postData } from "../APIs/BackendAPIs";

// Capture the token from the query string
const urlParams = new URLSearchParams(window.location.search);
const token = urlParams.get("token");

export const visitorInfo = async () => {
  const userAgent = navigator.userAgent;
  let browserName = "Unknown";
  let os = "Unknown";

  // Detect browser
  if (userAgent.includes("Chrome")) browserName = "Chrome";
  else if (userAgent.includes("Safari")) browserName = "Safari";
  else if (userAgent.includes("Firefox")) browserName = "Firefox";
  // ...add more as needed

  // Detect Operating System
  if (userAgent.includes("Win")) os = "Windows";
  else if (userAgent.includes("Mac")) os = "MacOS";
  else if (userAgent.includes("Linux")) os = "Linux";
  // ...add more as needed

  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;
  let deviceType;

  if (/mobile/i.test(userAgent)) {
    deviceType = "Mobile";
  } else {
    deviceType = "Desktop";
  }

  // Include the token in the data you send to the backend
  const data = {
    browserName,
    os,
    screenWidth,
    screenHeight,
    deviceType,
    token,
  };

  try {
    await postData("analytics/storeVisitorData", data);
  } catch (error) {
    console.error("Error storing visitor data: ", error);
  }
};
