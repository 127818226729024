import { useContext, useEffect } from "react";

import { ThemeContext } from "./ThemeContext";

export const BodyThemeProvider = ({ children }) => {
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    document.body.style.backgroundColor = theme.background;
    document.body.style.color = theme.color;
  }, [theme]);

  return children;
};
