import React from "react";

import "./DownloadCv.css";

const DownloadCv = () => {
  return (
    <div className="downloadCv-container">
      <h1>Download CV</h1>
      <ul>
        <li>Word Format</li>
        <li>PDF Format</li>
      </ul>
    </div>
  );
};

export default DownloadCv;
