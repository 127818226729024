import React, { useContext } from "react";

import { ThemeContext } from "../../utils/ThemeContext";
import "./MainHeader.css";

const MainHeader = (props) => {
  const { theme } = useContext(ThemeContext);

  return (
    <header
      className="main-header"
      style={{
        backgroundColor: theme.header.background,
        color: theme.header.color,
      }}
    >
      {props.children}
    </header>
  );
};

export default MainHeader;
