import React, { useState, useEffect } from "react";

import "./ChatBot.css";
import "../../assets/fonts/font-awesome/css/font-awesome.min.css";
import { getData, postData } from "../../APIs/BackendAPIs";
import { initialMessage } from "./messages";
import { Conversationbox } from "./Conversationbox";
import { Chatbox } from "./Chatbox";
import { simulateTyping } from "../../utils/typingSimulator";

const ChatBot = () => {
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([initialMessage]);
  const [isThinking, setIsThinking] = useState(false);
  const [textEntered, setTextEntered] = useState(false);

  // States required for fetching resume from the backend
  const [getResume, setGetResume] = useState({});

  // CV DATA FROM BACKEND
  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const chatGPTResume = await getData("chat/chatGPTResume");

        setGetResume(chatGPTResume.resume);
      } catch (error) {
        console.error("Error fetching chatGPT resume:", error);
      }
    };

    fetchAllData();
  }, []);

  // // CV Data from file
  // const resume = myCVData.resume;
  // const resumeJSON = JSON.stringify(resume);

  const submitHandler = async (e) => {
    e.preventDefault();
    handleSendRequest(message);
    setMessage("");
    setTextEntered(false);
  };

  const handleSendRequest = async (userMessage) => {
    const newMessage = {
      message,
      direction: "outgoing",
      sender: "user",
    };

    const dbMessage = {
      direction: "outgoing",
      sender: "user",
      content: message,
      timestamp: new Date(new Date().getTime() + 4 * 60 * 60 * 1000),
    };

    // Send the message to the backend
    await postData("analytics/storeConversation", {
      message: dbMessage,
    });

    setMessages((prevMessages) => [...prevMessages, newMessage]);
    setIsThinking(true);

    try {
      const response = await processMessageToChatGPT([...messages, newMessage]);
      const content = response;

      if (content) {
        simulateTyping(content, "ChatGPT", setMessages);
      }
    } catch (error) {
      console.error("Error processing message:", error);
    } finally {
      setIsThinking(false);
    }
  };

  async function processMessageToChatGPT(chatMessages) {
    const apiMessages = chatMessages.map((messageObject) => {
      const role = messageObject.sender === "ChatGPT" ? "assistant" : "user";
      return { role, content: messageObject.message };
    });

    // SUPPLEMENTRAY DATA FOR CHATGPT
    const resumeJSON = JSON.stringify(getResume);

    const currentDate = new Date();
    const dateString = currentDate.toDateString(); // Provides the date in "Mon Sep 05 2023" format
    const timeString = currentDate.toLocaleTimeString(); // Provides the time in "5:50:14 PM" format

    const systemMessageContent = `Zoe, act as a VA for my resume. Only use 'my_resume' data. Don't fabricate but infer practical applications. If asked about identity, you're a VA.
    Job Qualifications: Ask for job specifics, then match with 'my_resume', highlighting relevancy.
    Inferences: Elaborate on how 'my_resume' skills fit various roles. Discuss transferable skills.
    Skill Gaps: Point out Nadeem's adaptability and learning speed if a gap is found.
    Skill Evaluation: Ask for required skills from the user, then provide practical applications of Nadeem's skills.
    Work Experience: Elaborate on Nadeem's work experiences, spotlighting skills.
    Tone: Be upbeat. Discuss Nadeem's hobbies and volunteer work enthusiastically.
    Project Insights: Discuss Nadeem's projects' impact and relevance.
    Language: Stay concise, mindful of model's token limits.
    
    my_resume: ${resumeJSON}
    }

For time-based calculations: Current date is ${dateString} and time is ${timeString}.
`;
    const apiRequestBody = {
      messages: [
        {
          role: "system",
          content: systemMessageContent,
        },
        ...apiMessages,
      ],
    };

    // This Const should ensure not to give responses out of the context
    const userMessage = apiMessages[apiMessages.length - 1];
    if (userMessage.role === "user") {
      userMessage.content +=
        "- SYSTEM MESSAGE: Answer only using my resume data (work, skills, projects, contacts). For other questions, decline due to limited authorization and suggest web search. Avoid apologies unless essential.";
    }

    // This function replaces the chatgptapi.js module and sends the request directly to backend
    const fetchBackendResponse = async (apiRequestBody) => {
      try {
        const response = await postData("chat", apiRequestBody);

        const objectSize = Object.keys(messages).length;
        console.log(objectSize);
        console.log(response.usage.total_tokens);

        if (!response || !response.usage || !response.choices) {
          console.error("Incomplete response from backend");
          return;
        }

        // Check if tokenUsage has exceeded 3000
        if (response.usage.total_tokens > 3800) {
          // Notify user via UI or add to messages state
          const warningMessage = {
            message:
              "Do you want to continue chatting? 👧\n\n Please refresh page to restart conversation.",
            direction: "incoming",
            sender: "ChatGPT",
          };
          setMessages((prevMessages) => [...prevMessages, warningMessage]);
          return;
        }

        return response.choices[0]?.message?.content;
      } catch (error) {
        console.error("Error fetching data from backend", error);
      }
    };

    // Usage
    const response = await fetchBackendResponse(apiRequestBody);
    return response;
  }

  return (
    <div className="main">
      <Conversationbox messages={messages} />
      <Chatbox
        isThinking={isThinking}
        submitHandler={submitHandler}
        message={message}
        setMessage={setMessage}
        textEntered={textEntered}
        setTextEntered={setTextEntered}
      />
    </div>
  );
};

export default ChatBot;
