// home.js
import React, { useState, useEffect, useContext } from "react";

import "./home.css";
import { ThemeContext } from "../utils/ThemeContext";

const Home = () => {
  const { theme } = useContext(ThemeContext);

  const [dynamicText, setDynamicText] = useState("Programmer");

  useEffect(() => {
    // Define the options array outside of the changeText function
    const options = [
      "A Developer",
      "An Architect",
      "A Creative Thinker",
      "A Continuous Learner",
      "A Problem Solver",
      "Detail Oriented",
      "Analytical",
      "Innovative",
      "Social :-)",
    ];
    let currentIndex = 0;

    // Function to change the dynamic text every second
    const changeText = () => {
      // Get the current option
      const currentOption = options[currentIndex];

      // Update the dynamic text
      setDynamicText(currentOption);

      // Increment the index and loop back to the beginning if needed
      currentIndex = (currentIndex + 1) % options.length;
    };

    // Start changing the text every second
    const interval = setInterval(changeText, 1000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  const [isLastSection, setIsLastSection] = useState(false);
  const [isFirstSection, setIsFirstSection] = useState(false);

  const scrollToNextSection = () => {
    const sections = document.querySelectorAll(".full-screen-section");
    const currentScrollPos =
      window.pageYOffset || document.documentElement.scrollTop;

    const nextSectionIndex = Array.from(sections).findIndex(
      (section) => section.offsetTop > currentScrollPos
    );

    const nextSection = sections[nextSectionIndex];

    if (nextSection) {
      window.scrollTo({
        top: nextSection.offsetTop,
        behavior: "smooth",
      });
      setIsLastSection(nextSectionIndex === sections.length - 1);
    } else {
      setIsLastSection(true);
    }
  };

  const scrollToTop = () => {
    const firstSection = document.querySelector(".full-screen-section");
    if (firstSection) {
      window.scrollTo({
        top: firstSection.offsetTop,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;

      // Check if the scroll position is near the top of the page
      const isTop = currentScrollPos < 100; // Adjust '100' as needed
      setIsFirstSection(isTop);

      // Check if the scroll position is at the bottom of the page
      const isBottom =
        window.innerHeight + currentScrollPos >=
        document.documentElement.scrollHeight;
      setIsLastSection(isBottom);
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener when the component unmounts
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <section
        className="full-screen-section intro"
        style={{ backgroundColor: theme.li.background }}
      >
        <div className="half-width-container">
          <h1 className="large-font" style={{ color: theme.header.TitleColor }}>
            I'm...
          </h1>

          <h1
            className="large-font dynamic-font"
            style={{ color: theme.dynamicfont.color }}
          >
            {dynamicText}
          </h1>
          <h1 className="large-font" style={{ color: theme.header.TitleColor }}>
            I'm Nadeem!
          </h1>
        </div>
      </section>
      <section
        className="full-screen-section b1"
        style={{
          backgroundColor: theme.li.background1,
          color: theme.li.color,
        }}
      >
        <div className="contentsection">
          <h1>About Me</h1>
          <p>
            With a strong acumen in systems management and software development,
            my career is a tapestry of diverse roles, from hands-on technical
            expertise to leading innovative projects in multinational
            corporations. My specialization in full-stack development is
            complemented by profound skills in AI and Machine Learning, enabling
            me to devise solutions that significantly enhance operational
            efficiency. I excel in managing cloud infrastructures on platforms
            like Azure and AWS, underpinned by my proficiency in DevOps, API
            management, and database technologies. Passionate about continuous
            learning, I adeptly navigate through various programming languages
            and tools, always aiming to integrate the latest technologies in
            ever-evolving environments.
          </p>
        </div>
      </section>

      <section
        className="full-screen-section b2"
        style={{ backgroundColor: theme.li.background2, color: theme.li.color }}
      >
        <div className="contentsection">
          <h1>Professional Highlights</h1>
          <p>
            My journey in the tech industry has seen me embrace significant
            roles such as Sr. System Engineer at Etisalat and Technical Services
            Head at ICD Technologies LLC. A highlight of my tenure was being in
            charge of the AI/ML pilot project. In this role, I led the team
            through a comprehensive process, starting with training and task
            delegation. We collaboratively worked on identifying suitable use
            cases and sourcing relevant data. My involvement was crucial in
            overseeing the data ETL processes and preparing the data for Machine
            Learning applications. I played a hands-on role in testing various
            ML models, ensuring their suitability and effectiveness for our
            objectives. This project not only sharpened our team's technical
            prowess but also reinforced my leadership and strategic planning
            skills in a cutting-edge technology domain.
          </p>
        </div>
      </section>

      <section
        className="full-screen-section b3"
        style={{ backgroundColor: theme.li.background3, color: theme.li.color }}
      >
        <div className="contentsection">
          <h1>My Expertise</h1>
          <p>
            In my role as a Systems and Software Engineer, I am adept in DevOps,
            AI and Machine Learning, and full-stack application development
            using Python, JavaScript, React, and Node.js. My experience extends
            to managing and optimizing cloud infrastructures on Azure and AWS
            platforms, coupled with strong API management skills. Proficient in
            database technologies like MongoDB and MySQL, I employ tools such as
            Git, Jenkins, and Jupyter Notebook to enhance development processes.
            I am continuously expanding my knowledge, particularly in AI-driven
            development, and thrive in dynamic, technology-driven environments,
            always seeking new challenges and growth opportunities.
          </p>
        </div>
      </section>

      {!isLastSection && (
        <button onClick={scrollToNextSection} className="scroll-down-button">
          <i className="fa fa-arrow-down"></i>
        </button>
      )}
      {!isFirstSection && (
        <button onClick={scrollToTop} className="scroll-up-button">
          <i className="fa fa-arrow-up"></i>
        </button>
      )}
    </>
  );
};

export default Home;
