import React from "react";

import "./DemoProjects.css";

const PersonalProjects = () => {
  return (
    <div className="personalProjects">
      <h1 className="centered-content">DEMO PROJECTS</h1>
      <div className="project-container">
        <div className="project-img">
          <img
            src="https://media.istockphoto.com/id/1461188388/vector/landing-page-abstract-wave-background-website-template-for-websites-or-apps-modern-design.jpg?s=170667a&w=0&k=20&c=gLxY0wSNzef2PUKjlSdWPomU8WBBcVfZYkLqqunLqiE="
            alt="Project Screenshot"
          />
        </div>
        <div className="project-info">
          <h2>Project A</h2>
          <p className="project-desc">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Viverra
            orci sagittis eu volutpat odio facilisis mauris. Pharetra massa
            massa ultricies mi. Habitant morbi tristique senectus et netus et
            malesuada. Sapien et ligula ullamcorper malesuada proin libero.
            Ullamcorper sit amet risus nullam eget felis eget nunc lobortis. Sed
            turpis tincidunt id aliquet risus. Suspendisse in est ante in nibh
            mauris cursus mattis molestie. In fermentum et sollicitudin ac orci
            phasellus. Maecenas accumsan lacus vel facilisis volutpat est velit.
          </p>
          <p className="project-usecase">Use Case: Web Application</p>
          <p className="project-tech">Technologies: ReactJS, NodeJS</p>
          <a
            href="your-demo-url-here"
            target="_blank"
            rel="noopener noreferrer"
            className="demo-link"
          >
            See Demo
          </a>
        </div>
      </div>
      <div className="project-container">
        <div className="project-img">
          <img
            src="https://media.istockphoto.com/id/1461188388/vector/landing-page-abstract-wave-background-website-template-for-websites-or-apps-modern-design.jpg?s=170667a&w=0&k=20&c=gLxY0wSNzef2PUKjlSdWPomU8WBBcVfZYkLqqunLqiE="
            alt="Project Screenshot"
          />
        </div>
        <div className="project-info">
          <h2>Project A</h2>
          <p className="project-desc">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Viverra
            orci sagittis eu volutpat odio facilisis mauris. Pharetra massa
            massa ultricies mi. Habitant morbi tristique senectus et netus et
            malesuada. Sapien et ligula ullamcorper malesuada proin libero.
            Ullamcorper sit amet risus nullam eget felis eget nunc lobortis. Sed
            turpis tincidunt id aliquet risus. Suspendisse in est ante in nibh
            mauris cursus mattis molestie. In fermentum et sollicitudin ac orci
            phasellus. Maecenas accumsan lacus vel facilisis volutpat est velit.
          </p>
          <p className="project-usecase">Use Case: Web Application</p>
          <p className="project-tech">Technologies: ReactJS, NodeJS</p>
          <a
            href="your-demo-url-here"
            target="_blank"
            rel="noopener noreferrer"
            className="demo-link"
          >
            See Demo
          </a>
        </div>
      </div>
    </div>
  );
};

export default PersonalProjects;
